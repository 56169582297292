import { useContext } from 'react';

import { AdminContext } from '../../contexts/admin.context';

import Button from './Button';
import Spinner from './Spinner';
import { DownloadIconWithBar } from '../icons';

export const DownloadButton = ({
  onClick,
  isLoading = false,
  children,
  buttonType = 'outline',
  bg = ''
}) => {
  const { themeConfig: { primary } = {} } = useContext(AdminContext);

  return (
    <Button type="button" buttonType={buttonType} bg={bg} onClick={onClick}>
      {isLoading ? (
        <Spinner />
      ) : (
        <span className="flex gap-2 items-center">
          {children}
          <DownloadIconWithBar color={buttonType === 'primary' ? '#fff' : primary} />
        </span>
      )}
    </Button>
  );
};
