import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { PDFDocument, StandardFonts } from 'pdf-lib';

export const downloadFile = (data, fileName) => {
  try {
    const href = URL.createObjectURL(data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  } catch (error) {
    // Normally download with URL
    const link = document.createElement('a');
    link.href = data;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
  }
};

export const downloadPDFWithURL = async (url, fileName) => {
  try {
    const parsedURL = JSON.parse(url) || url;
    const response = await fetch(parsedURL);
    const content = await response.blob();
    downloadFile(content, fileName);
  } catch (error) {
    try {
      const response = await fetch(url);
      const content = await response.blob();
      downloadFile(content, fileName);
    } catch (error) {
      console.error(error);
    }
    console.error(error);
  }
};

const fetchFileContent = async (url) => {
  const response = await fetch(url);
  const content = await response.blob();
  return content;
};

export const downloadPDFsFromURLAsZip = async (zipURL, fileName = 'archive') => {
  const content = await fetchFileContent(zipURL);
  saveAs(content, `${fileName}.zip`);
};

export const combinePDFsFromURLs = async (zipURL, fileName = 'files') => {
  const content = await fetchFileContent(zipURL);

  // Generate a blob from the zip and load it again
  const zipFile = await JSZip.loadAsync(content);
  const fileNames = Object.keys(zipFile.files);

  // Load each PDF from the zip
  const pdfDocs = await Promise.all(
    fileNames
      .filter((fileName) => fileName.endsWith('.pdf'))
      .map(async (fileName) => {
        const file = await zipFile.file(fileName).async('arrayBuffer');
        return await PDFDocument.load(file);
      })
  );

  const mergedPdfDoc = await PDFDocument.create();
  const helveticaFont = await mergedPdfDoc.embedFont(StandardFonts.Helvetica);

  // Merge the PDFs
  for (let i = 0; i < pdfDocs.length; i++) {
    const pdfDoc = pdfDocs[i];
    const copiedPages = await mergedPdfDoc.copyPages(pdfDoc, pdfDoc.getPageIndices());
    for (let j = 0; j < copiedPages.length; j++) {
      const copiedPage = copiedPages[j];
      copiedPage.setFont(helveticaFont);
      copiedPage.drawText(`${fileNames[i]} - Page ${j + 1}`, {
        x: 50,
        y: 50,
        size: 12,
        font: helveticaFont
      });
      mergedPdfDoc.addPage(copiedPage);
    }
  }

  // Save and download the merged PDF
  const mergedPdfBytes = await mergedPdfDoc.save();
  const mergedBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });

  saveAs(mergedBlob, `${fileName}.pdf`);
};
