import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { MailToIcon, TelePhoneIcon } from '../../assets/logos';
import {
  FacebookIcon,
  InstagramIcon,
  LinkdInIcon,
  TikTokIcon,
  TwitterIcon,
  YoutubeIcon
} from '../icons';
import Button from '../common/Button';
import { getTranslation } from '../../utils/translation';

export default function Footer({ removeTopSpacing }) {
  const { t } = useTranslation();

  return (
    <footer>
      <div
        className={clsx('diagonal-section-top--flip-x m-0 bg-[#ededed] py-10 ch-md:pt-24', {
          '-mt-8 md:-mt-12': removeTopSpacing
        })}>
        <div className="sm:container sm:px-8 ch-md:px-10 mx-auto px-7">
          <div className="pt-8">
            <div className="flex m-0 p-0 flex-row flex-wrap">
              <div className="text-center ch-md:text-left max-w-full w-full ch-md:w-auto my-5 md:py-0">
                <h5
                  className="text-xl mb-7 font-bold"
                  dangerouslySetInnerHTML={{
                    __html: getTranslation(t, 'landing.footer.contac_info.title')
                  }}
                />
                <div className="my-5">
                  <p className="mb-5">
                    <a
                      href="https://google.com/maps?q=Schwarztorstrasse 87, 3007 Bern"
                      target="_blank"
                      rel="noreferrer"
                      dangerouslySetInnerHTML={{
                        __html: getTranslation(t, 'landing.footer.heading')
                      }}></a>
                  </p>
                  <p className="mt-5">
                    <a href="mailto:info@swiss-skills.ch" className="block">
                      <span className="inline-block mr-4 leading-[0] fill-current relative top-1.5">
                        <MailToIcon />
                      </span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: getTranslation(t, 'landing.footer.contac_info.info2')
                        }}
                      />
                    </a>
                    <a href="tel:+41315520515" className="block">
                      <span className="inline-block mr-4 leading-[0] fill-current relative top-1.5">
                        <TelePhoneIcon />
                      </span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: getTranslation(t, 'landing.footer.contac_info.info3')
                        }}
                      />
                    </a>
                  </p>
                </div>
              </div>
              <div className="sm:pl-4 text-center ch-sm:text-left max-w-full w-full ch-md:w-1/2 my-5 ch-md:py-0 ch-md:flex-1 ch-lg:pl-10">
                <div className="flex ch-md:justify-center justify-center">
                  <div>
                    <h5
                      className="text-xl mb-7 font-bold"
                      dangerouslySetInnerHTML={{
                        __html: getTranslation(t, 'landing.footer.more_info.title')
                      }}
                    />
                    <div className="my-5 ">
                      <ul className="!list-none !p-0">
                        <li className="flex justify-center ch-md:justify-start">
                          <a
                            href={getTranslation(t, 'landing.footer.more_info.infoSixLink')}
                            target="_blank"
                            rel="noreferrer"
                            className="py-0 sm:py-1.5">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getTranslation(t, 'landing.footer.more_info.info6')
                              }}
                            />
                          </a>
                        </li>
                        <li className="flex justify-center ch-md:justify-start">
                          <a
                            href={getTranslation(t, 'landing.footer.more_info.infoTwoLink')}
                            target="_blank"
                            rel="noreferrer"
                            className="py-0 sm:py-1.5">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getTranslation(t, 'landing.footer.more_info.info2')
                              }}
                            />
                          </a>
                        </li>
                        <li className="flex justify-center ch-md:justify-start">
                          <a
                            href={getTranslation(t, 'landing.footer.more_info.infoThreeLink')}
                            target="_blank"
                            rel="noreferrer"
                            className="py-0 sm:py-1.5">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getTranslation(t, 'landing.footer.more_info.info4')
                              }}
                            />
                          </a>
                        </li>
                        <li className="flex justify-center ch-md:justify-start">
                          <a
                            href={getTranslation(t, 'landing.footer.more_info.infoFourLink')}
                            target="_blank"
                            rel="noreferrer"
                            className="py-0 sm:py-1.5">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getTranslation(t, 'landing.footer.more_info.info5')
                              }}
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="flex ch-md:hidden justify-center ch-md:justify-start text-center ch-md:text-start">
                  <div>
                    <h5
                      className="text-xl mb-7 font-bold"
                      dangerouslySetInnerHTML={{
                        __html: getTranslation(t, 'landing.footer.news_letter.title')
                      }}
                    />
                    <div className="my-5">
                      <a
                        href={getTranslation(t, 'landing.footer.news_letter.link')}
                        target="_blank"
                        rel="noreferrer"
                        className="bg-swiss-300 text-white border-2 border-transparent shadow-[0_1px_5px_rgba(0,86,89,1)] rounded-[100px] leading-[1.4] px-4 py-2.5 hover:bg-swiss-200 font-bold text-sm ch-xl:text-base ">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: getTranslation(t, 'landing.footer.news_letter.button_content')
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="mb-0 flex ch-md:hidden items-center justify-center ch-md:justify-center text-center">
                  <div>
                    <h5
                      className="text-xl mb-7 font-bold mt-5"
                      dangerouslySetInnerHTML={{
                        __html: getTranslation(t, 'landing.footer.social_media.titile')
                      }}
                    />
                    <div className="my-5">
                      <ul className="flex flex-wrap column gap-x-1.5 social-icons !list-none !p-0">
                        <li className="inline-block mb-4 ch-md:mb-4">
                          <a
                            href="https://www.instagram.com/swissskills/"
                            title="Instagram"
                            aria-label="Instagram"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="flex items-center justify-center bg-white h-8 w-8 rounded-full text-swiss-300 shadow-[0_1px_5px_rgba(0,86,89,0.38)]">
                            <span
                              data-uk-icon="icon: instagram; ratio: 1.15"
                              className="fill-current">
                              <InstagramIcon />
                            </span>
                          </a>
                        </li>
                        <li className="inline-block mb-4 ch-md:mb-4">
                          <a
                            href="https://www.facebook.com/swissskills/"
                            title="Facebook"
                            aria-label="Facebook"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="flex items-center justify-center bg-white h-8 w-8 rounded-full text-swiss-300 shadow-[0_1px_5px_rgba(0,86,89,0.38)]">
                            <span
                              data-uk-icon="icon: facebook; ratio: 1.15"
                              className="fill-current">
                              <FacebookIcon />
                            </span>
                          </a>
                        </li>
                        <li className="inline-block mb-4 ch-md:mb-4">
                          <a
                            href="https://www.linkedin.com/company/swissskills"
                            title="Linkedin"
                            aria-label="Linkedin"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="flex items-center justify-center bg-white h-8 w-8 rounded-full text-swiss-300 shadow-[0_1px_5px_rgba(0,86,89,0.38)]">
                            <span
                              data-uk-icon="icon: linkedin; ratio: 1.15"
                              className="fill-current">
                              <LinkdInIcon />
                            </span>
                          </a>
                        </li>
                        <li className="inline-block mb-4 ch-md:mb-4">
                          <a
                            href="https://www.tiktok.com/@swissskills"
                            title="Tiktok"
                            aria-label="Tiktok"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="flex items-center justify-center bg-white h-8 w-8 rounded-full text-swiss-300 shadow-[0_1px_5px_rgba(0,86,89,0.38)]">
                            <span data-uk-icon="icon: tiktok; ratio: 1.15" className="fill-current">
                              <TikTokIcon />
                            </span>
                          </a>
                        </li>
                        <li className="inline-block mb-4 ch-md:mb-4">
                          <a
                            href="https://twitter.com/SwissSkills"
                            title="Twitter"
                            aria-label="Twitter"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="flex items-center justify-center bg-white h-8 w-8 rounded-full text-swiss-300 shadow-[0_1px_5px_rgba(0,86,89,0.38)]">
                            <span
                              data-uk-icon="icon: twitter; ratio: 1.15"
                              className="fill-current">
                              <TwitterIcon />
                            </span>
                          </a>
                        </li>
                        <li className="inline-block mb-4 ch-md:mb-4">
                          <a
                            href="https://www.youtube.com/user/SwissSkills"
                            title="Youtube"
                            aria-label="Youtube"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="flex items-center justify-center bg-white h-8 w-8 rounded-full text-swiss-300 shadow-[0_1px_5px_rgba(0,86,89,0.38)]">
                            <span
                              data-uk-icon="icon: youtube; ratio: 1.15"
                              className="fill-current">
                              <YoutubeIcon />
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden ch-md:block lg:pl-4 text-center ch-sm:text-left max-w-full w-full ch-md:w-1/2 my-5 ch-md:py-0 ch-md:flex-1 ch-lg:pl-10">
                <div className="mb-0 flex items-center justify-center ch-sm:justify-start ch-md:justify-center">
                  <div>
                    <h5
                      className="text-xl mb-7 font-bold"
                      dangerouslySetInnerHTML={{
                        __html: getTranslation(t, 'landing.footer.social_media.titile')
                      }}
                    />
                    <div className="my-5">
                      <ul className="flex flex-wrap column gap-x-4 social-icons !list-none !p-0">
                        <li className="inline-block mb-4 ch-md:mb-4">
                          <a
                            href="https://www.instagram.com/swissskills/"
                            title="Instagram"
                            aria-label="Instagram"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="flex items-center justify-center bg-white h-8 w-8 rounded-full text-swiss-300 shadow-[0_1px_5px_rgba(0,86,89,0.38)]">
                            <span
                              data-uk-icon="icon: instagram; ratio: 1.15"
                              className="fill-current">
                              <InstagramIcon />
                            </span>
                          </a>
                        </li>
                        <li className="inline-block mb-4 ch-md:mb-4">
                          <a
                            href="https://www.facebook.com/swissskills/"
                            title="Facebook"
                            aria-label="Facebook"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="flex items-center justify-center bg-white h-8 w-8 rounded-full text-swiss-300 shadow-[0_1px_5px_rgba(0,86,89,0.38)]">
                            <span
                              data-uk-icon="icon: facebook; ratio: 1.15"
                              className="fill-current">
                              <FacebookIcon />
                            </span>
                          </a>
                        </li>
                        <li className="inline-block mb-4 ch-md:mb-4">
                          <a
                            href="https://www.linkedin.com/company/swissskills"
                            title="Linkedin"
                            aria-label="Linkedin"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="flex items-center justify-center bg-white h-8 w-8 rounded-full text-swiss-300 shadow-[0_1px_5px_rgba(0,86,89,0.38)]">
                            <span
                              data-uk-icon="icon: linkedin; ratio: 1.15"
                              className="fill-current">
                              <LinkdInIcon />
                            </span>
                          </a>
                        </li>
                        <li className="inline-block mb-4 ch-md:mb-4">
                          <a
                            href="https://www.tiktok.com/@swissskills"
                            title="Tiktok"
                            aria-label="Tiktok"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="flex items-center justify-center bg-white h-8 w-8 rounded-full text-swiss-300 shadow-[0_1px_5px_rgba(0,86,89,0.38)]">
                            <span data-uk-icon="icon: tiktok; ratio: 1.15" className="fill-current">
                              <TikTokIcon />
                            </span>
                          </a>
                        </li>
                        <li className="inline-block mb-4 ch-md:mb-4">
                          <a
                            href="https://twitter.com/SwissSkills"
                            title="Twitter"
                            aria-label="Twitter"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="flex items-center justify-center bg-white h-8 w-8 rounded-full text-swiss-300 shadow-[0_1px_5px_rgba(0,86,89,0.38)]">
                            <span
                              data-uk-icon="icon: twitter; ratio: 1.15"
                              className="fill-current">
                              <TwitterIcon />
                            </span>
                          </a>
                        </li>
                        <li className="inline-block mb-4 ch-md:mb-4">
                          <a
                            href="https://www.youtube.com/user/SwissSkills"
                            title="Youtube"
                            aria-label="Youtube"
                            target="_blank"
                            rel="noreferrer noopener"
                            className="flex items-center justify-center bg-white h-8 w-8 rounded-full text-swiss-300 shadow-[0_1px_5px_rgba(0,86,89,0.38)]">
                            <span
                              data-uk-icon="icon: youtube; ratio: 1.15"
                              className="fill-current">
                              <YoutubeIcon />
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden ch-md:block sm:pl-4 text-center ch-sm:text-left max-w-full w-full ch-md:w-1/2 my-5 ch-md:py-0 ch-md:flex-1 ch-lg:pl-10">
                <div className="flex justify-start ch-md:justify-end ">
                  <div>
                    <h5
                      className="text-xl mb-7 font-bold"
                      dangerouslySetInnerHTML={{
                        __html: getTranslation(t, 'landing.footer.news_letter.title')
                      }}
                    />
                    <div className="my-5 md:px-0">
                      <Button
                        isExternalLink
                        buttonType="ch-landing"
                        link={getTranslation(t, 'landing.footer.news_letter.link')}>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: getTranslation(t, 'landing.footer.news_letter.button_content')
                          }}
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
