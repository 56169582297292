import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import { TIME_SLOTS } from '../data/default-values';
import { AdminContext } from '../contexts/admin.context';
import { WorkshopContext } from '../contexts/workshop.context';
import { createWorkshop, rescheduleWorkshop } from '../service';

import { EnglishIconSvg, FrenchIconSvg, GermanIconSvg, ItalianIconSvg } from '../icon_svg';

import 'react-datepicker/dist/react-datepicker.css';
import { TimeSlotList } from './workshop/timeSlotList';
import Spinner from './common/Spinner';
import { convertTo12Hrs } from '../utils/date';
import { getTranslation } from '../utils/translation';

const ScheduleModel = ({ onUpdate, primary, secondary }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user, themeId, teamType } = useContext(AdminContext);
  const { setActiveStep, workshopData, setWorkshopData } = useContext(WorkshopContext);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date().toISOString());
  const [stateChange, setStateChange] = useState(false);
  const [teamName, setTeamName] = useState(workshopData.teamName);
  const [moderatorName, setModeratorName] = useState(workshopData?.moderatorName);
  const [showTeamNameError, setShowTeamNameError] = useState('');

  const startDate = new Date();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [nextPrev, setNextPrev] = useState(true);
  const [timeSlots, setTimeSlots] = useState(TIME_SLOTS);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const basePath = `${themeId}/${teamType}`;

  useEffect(() => {
    if (workshopData.workshopDate) {
      onDateChange(workshopData.workshopDate);
    }
  }, [workshopData.workshopDate]);

  const onDateChange = (date) => {
    try {
      const modalDate = new Date(date) || new Date();
      // Check id date is valid Date object
      if (!(modalDate instanceof Date)) return;
      setSelectedDate(modalDate);
      let dateSelected = modalDate?.toISOString();
      setWorkshopData((currVal) => ({
        ...currVal
      }));
      setFinalDate(dateSelected);
    } catch (error) {
      console.error('Error in onDateChange', error);
    }
  };

  const resetTimeSlots = () => {
    for (let i = 0; i < timeSlots.length; i++) {
      timeSlots[i].isStartOfWorkshop = false;
      timeSlots[i].isFirstClick = false;
    }
  };

  const addWorkshop = (timeSlotStartIndex) => {
    const startTime = timeSlots[timeSlotStartIndex].time.toLowerCase();
    const stringDate = finalDate.split('T');
    let confirmDateTime = new Date(stringDate[0]).toDateString();
    setWorkshopData((currVal) => ({
      ...currVal,
      workshopDate: confirmDateTime,
      workshopTime: convertTo12Hrs(startTime)
    }));

    // CHECK IF TIME IS PASSED CURRENT TIME
    const previousDatObject = new Date();
    const lastDay = previousDatObject.getDate() - 1;
    previousDatObject.setDate(lastDay);
    const isSelectedTimePassed = new Date(previousDatObject) > new Date(confirmDateTime);

    if (isSelectedTimePassed) {
      toast.info(getTranslation(t, 'workshops.info.selectFutureSlot'));
      return;
    }

    if (!user) {
      return navigate(basePath + '/login');
    }

    const { firstname: userName } = user;

    let utcDiff = new Date().getTimezoneOffset();
    const timeZoneDiff = utcDiff / 60;

    let reqBody = {
      userName,
      type: workshopData.type,
      languageId: workshopData.languageId,
      workshopTime: convertTo12Hrs(startTime),
      workshopDate: confirmDateTime,
      teamName,
      moderatorName,
      timeZone,
      timeZoneDiff,
      groupSize: workshopData.groupSize,
      estimatedDuration: workshopData.estimated_duration,
      platform: 'mft'
    };

    if (workshopData.workshopId) {
      reqBody.workshopId = workshopData.workshopId;
    }

    bookSlot(reqBody);
  };

  const handleTimeSlotSelect = (index) => {
    let timeSlot = timeSlots.at(index);
    if (timeSlot.isBooked === true) return;
    if (timeSlot.isStartOfWorkshop === false) {
      if (index + 2 > timeSlots.length) return;
      for (let i = index; i <= index + 2; i++) {
        const currentSlot = timeSlots[i];
        if (currentSlot?.isBooked === true) return;
      }
      resetTimeSlots();
      timeSlots[index].isFirstClick = true;
      timeSlots[index].isStartOfWorkshop = true;
      for (let i = index + 1; i <= index + 2; i++) {
        if (!!timeSlots[i]) {
          timeSlots[i].isFirstClick = true;
        }
      }
      setTimeSlots((oldTimeSlots) => oldTimeSlots);
      setStateChange(!stateChange);
    } else if (timeSlots[index].isStartOfWorkshop === true) {
      if (teamName === '') {
        setShowTeamNameError(true);
        return;
      }
      setWorkshopData((currVal) => ({
        ...currVal,
        teamName
      }));
      addWorkshop(index);
    }
  };

  const bookSlot = async (reqBody) => {
    try {
      if (isSubmitting) {
        return false;
      }
      setIsSubmitting(true);
      if (reqBody.workshopId) {
        const response = await rescheduleWorkshop(reqBody);
        if (response.status !== 200) {
          toast.error(getTranslation(t, 'workshops.info.bookingError'));
          return;
        }
        setActiveStep('step5');
      } else {
        const response = await createWorkshop(reqBody);
        if (response.status !== 200) {
          toast.error(getTranslation(t, 'workshops.info.bookingError'));
          return;
        }
        setActiveStep('step5');
      }
    } catch (e) {
      toast.error(getTranslation(t, 'workshops.info.bookingError'));
    } finally {
      setWorkshopData((currVal) => ({
        ...currVal,
        workshopDate: '',
        workshopTime: ''
      }));
      resetTimeSlots();
      setFinalDate(new Date().toISOString());
      setSelectedDate(new Date());
      setIsSubmitting(false);
      onUpdate();
    }
  };

  const updateTeamName = (value) => {
    setTeamName(value);
    setShowTeamNameError('');
  };

  const openModallanguage = () => {
    setActiveStep('step3');
  };

  const handleChangeSlots = (value) => {
    if (value === 'next') {
      setNextPrev(false);
    } else {
      setNextPrev(true);
    }
  };

  return (
    <>
      <div className="datePickerandtime-row md:flex block">
        {isSubmitting && (
          <div className="absolute w-full h-full flex items-center justify-center bg-swiss-100 opacity-70 z-50">
            <Spinner size={48} />
          </div>
        )}
        <div
          className="datePicker-column p-6 lg:w-[397px] w-full flex flex-col items-center justify-center"
          style={{ backgroundColor: secondary }}>
          <div className="datepicker-block">
            <DatePicker
              locale={'en'}
              calendarStartDay={1}
              startDate={startDate}
              selected={selectedDate}
              minDate={startDate}
              selectsDisabledDaysInRange={false}
              inline
              onChange={(date) => onDateChange(date)}
              formatWeekDay={(nameOfDay) =>
                getTranslation(t, `workshops.newWorkshop.calendar.days.${nameOfDay.toLowerCase()}`)
              }
            />
          </div>
          <div className="mission-details-block text-center">
            <div className="mission-info font-semibold">
              <h2
                className="text-xl text-white"
                dangerouslySetInnerHTML={{
                  __html: getTranslation(
                    t,
                    'workshops.newWorkshop.selectDateAndTime.workshopDetailsSectionLabel'
                  )
                }}
              />

              <p className="text-base text-[#191919] pb-2 flex items-center justify-center gap-2">
                <span
                  dangerouslySetInnerHTML={{
                    __html: getTranslation(
                      t,
                      'workshops.newWorkshop.selectDateAndTime.noOfParticipantsLabel'
                    )
                  }}
                />
                <span className="text-white"> {workshopData.groupSize}</span>
              </p>
            </div>

            {/* Team Name field */}
            <div className="mission-input my-2">
              <input
                type="text"
                id="moderatorName"
                placeholder={getTranslation(t, 'workshops.tableHeader.teamName')}
                className={clsx(
                  'bg-gray-100 text-xs rounded-none rounded-t-lg shadow ring-1 ring-swiss-200/10 focus:outline-none focus:bg-white focus:ring-swiss-300 appearance-none',
                  {
                    '!bg-red-100 ring-1 !ring-red-700 placeholder-red-800/50': showTeamNameError
                  }
                )}
                value={teamName}
                onChange={(e) => updateTeamName(e.target.value)}
              />
            </div>
            {/* Moderator Name field */}
            <div className="mission-input my-2">
              <input
                type="text"
                id="moderatorName"
                placeholder={getTranslation(
                  t,
                  'workshops.newWorkshop.selectDateAndTime.moderatorNamePlaceholder'
                )}
                className={
                  'bg-gray-100 text-xs rounded-none rounded-t-lg shadow ring-1 ring-swiss-200/10 focus:outline-none focus:bg-white focus:ring-swiss-300 appearance-none'
                }
                value={moderatorName}
                onChange={(e) => setModeratorName(e.target.value)}
              />
            </div>
            <p className="text-xs font-semibold text-white mt-3.5 px-4">
              {getTranslation(t, 'workshops.newWorkshop.selectDateAndTime.teamNameSubtext')}
            </p>
          </div>
        </div>

        <div className="timeslot-column px-6 py-4 w-full">
          <div className="timeslot-header flex md:flex-nowrap flex-wrap justify-between mb-4">
            <div className="timeslot-left mt-3.5">
              <h2 className="text-lg font-bold text-swiss-bw-100">
                {getTranslation(t, 'workshops.newWorkshop.selectDateAndTime.title')}
              </h2>
              <p className="text-sm text-swiss-bw-200 mb-5">
                {getTranslation(t, 'workshops.newWorkshop.selectDateAndTime.subtext')}
              </p>
            </div>
            <div className="timeslot-right">
              <div className="changelanguage-block cursor-pointer" onClick={openModallanguage}>
                <div className="flex">
                  <span className="md:ml-auto m-0">
                    {workshopData?.languageId === 'en' && <EnglishIconSvg />}
                    {workshopData?.languageId === 'de' && <GermanIconSvg />}
                    {workshopData?.languageId === 'it' && <ItalianIconSvg />}
                    {workshopData?.languageId === 'fr' && <FrenchIconSvg />}
                  </span>
                </div>
                <p className="text-sm text-right" style={{ color: primary }}>
                  {getTranslation(t, 'workshops.newWorkshop.selectDateAndTime.changeLanguageLabel')}
                </p>
              </div>
            </div>
          </div>
          <TimeSlotList
            timeSlots={timeSlots}
            timeZone={timeZone}
            selectTimeSlot={handleTimeSlotSelect}
            onChangeSlots={handleChangeSlots}
            translationFn={t}
            nextPrev={nextPrev}
            primary={primary}
            secondary={secondary}
          />
        </div>
      </div>
    </>
  );
};

export default ScheduleModel;
