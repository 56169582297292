import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { AdminContext } from '../contexts/admin.context';

import Sidebar from './Sidebar';
import Button from './common/Button';
import ConfirmationPopup from './common/ConfirmationPopup';
import ContentPopup from './common/ContentPopup';
import FullScreenLoader from './common/fullScreenLoader';
import LanguageSelectDropdown from './common/languageSelectDropdown';

import { CONTENT_POPUP_DEFAULT_VALUES, POPUP_DEFAULT_VALUES } from '../data/default-values';
import {
  getAccountDetails,
  getUserDetails,
  setAuthToken,
  setOrgHeaders,
  teacherApi
} from '../service';
import { getTranslation, setRegion } from '../utils/translation';

const DashboardLayout = ({ basePath }) => {
  const navigate = useNavigate();

  const { i18n, t } = useTranslation();
  const { pathname } = useLocation();

  const [languageOptions, setLanguageOptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [openSidebar, setOpenSidebar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const pageHasBackButton = pathname.includes('/edit-project');

  const {
    popupInfo,
    setPopupInfo,
    contentPopupInfo,
    setContentPopupInfo,
    themeId,
    teamType,
    setTRegion,
    user,
    setUser,
    portalConfig: { adminPanelTabs } = {},
    themeConfig: { secondary } = {},
    portalConfig: { languages } = {}
  } = useContext(AdminContext);

  const handlePopupHide = () => {
    // TODO: update
    setPopupInfo(POPUP_DEFAULT_VALUES);
  };

  const handleContentPopupHide = () => {
    setContentPopupInfo(CONTENT_POPUP_DEFAULT_VALUES);
  };

  const handleChange = (lang) => {
    setSelectedLanguage(lang);
    // setLanguageId(e.value);
    i18n.changeLanguage(lang.value);
    try {
      localStorage?.setItem('lang', lang.value);
    } catch (error) {
      console.log('LS unavailable');
    }
  };

  const handleLogout = () => {
    try {
      localStorage?.removeItem('user');
    } catch (error) {
      console.log('error', error);
    }
    navigate(basePath + '/login');
  };

  // Update translation prefix if applies
  useEffect(() => {
    if (!user) return;

    try {
      const userRegion = user?.address?.[0]?.street;

      const regionTranslationExisting = Object.keys(adminPanelTabs)?.find(
        (key) => key === userRegion
      );
      if (!regionTranslationExisting) {
        setTRegion('');
        setRegion('');
        return;
      }

      setTRegion(userRegion);
      setRegion(userRegion);
    } catch (error) {
      console.log('error', error);
    }
  }, [user]);

  // Get logged user data
  useEffect(() => {
    const options = languages?.map((lang) => ({
      label: lang?.toUpperCase(),
      value: lang
    }));
    setLanguageOptions(options);

    let lastLanguage = languages?.[0];

    try {
      lastLanguage = localStorage?.getItem('lang') ?? 'fr';
    } catch (error) {
      console.log('LS unavailable');
    }

    try {
      try {
        const token = localStorage.getItem('token');
        setAuthToken(token);
      } catch (error) {
        console.log('LS unavailable');
      }

      const token = teacherApi.defaults.headers.common['Authorization'];
      if (!token || token === 'Bearer null') throw new Error('No token found');

      setOrgHeaders(themeId, teamType);

      (async () => {
        try {
          const response = await getUserDetails();
          const accountDetailsResponse = await getAccountDetails();
          const finalUserObject = {
            ...response.data,
            address: accountDetailsResponse.data?.addresses,
            phone: accountDetailsResponse.data?.phone
          };
          setUser(finalUserObject);

          const setLanguage = languages?.includes(lastLanguage)
            ? { label: lastLanguage.toUpperCase(), value: lastLanguage }
            : languages.includes(finalUserObject?.languageId)
            ? {
                label: finalUserObject?.languageId.toUpperCase(),
                value: finalUserObject?.languageId
              }
            : options?.[0];

          setSelectedLanguage(setLanguage);
          i18n.changeLanguage(setLanguage.value);
        } catch (error) {
          console.log('error', error);
          navigate(basePath + '/login');
        } finally {
          setIsLoading(false);
        }
      })();
    } catch (e) {
      console.log('error', e);
      navigate(basePath + '/login');
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth < 640) {
      setOpenSidebar(false);
    } else {
      setOpenSidebar(true);
    }
  }, [window.innerWidth]);

  if (isLoading) return <FullScreenLoader />;

  return (
    <>
      {/* POPUP */}
      {popupInfo.isVisible && (
        <ConfirmationPopup
          heading={popupInfo.heading}
          icon={popupInfo.icon}
          description={popupInfo.description}
          action={popupInfo.action}
          textBox={popupInfo.textBox}
          footerNotes={popupInfo.footerNotes}
          actionList={popupInfo.actionList}
          actionListClick={popupInfo.actionListClick}
          onHide={handlePopupHide}
        />
      )}
      {contentPopupInfo.isVisible && (
        <ContentPopup
          content={contentPopupInfo.content}
          maxWidth={contentPopupInfo.maxWidth}
          onHide={handleContentPopupHide}
        />
      )}

      <div className="min-h-screen w-full flex flex-col overflow-hidden bg-white pt-10">
        {/* BAR */}
        {/* <div className="w-full lg:block">
              <div className="bg-primary h-8 w-full"></div>
            </div> */}
        {/* Language Select option */}
        <div className="absolute right-10 top-3 z-10 flex flex-row items-center justify-center gap-5">
          {languageOptions?.length > 1 && (
            <LanguageSelectDropdown
              themeId={themeId}
              value={selectedLanguage}
              options={languageOptions}
              onChange={handleChange}
              className={'!mb-0'}
              styleType="mini"
            />
          )}

          <Button buttonType="primary" onClick={handleLogout}>
            {getTranslation(t, 'sideBar.logoutButton')}
          </Button>
        </div>
        {/* MAIN LAYOUT */}
        <div className="flex flex-grow">
          {openSidebar && <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />}
          <div
            className={clsx(
              'w-full',
              { 'md:pl-[350px]': openSidebar },
              {
                'bg-white': pageHasBackButton
              }
            )}>
            <div className="relative px-8 lg:px-16 pt-10 w-full">
              <button
                className="mobile-menu-open mt-2 mb-6 p-2 rounded-md"
                style={{ backgroundColor: secondary, display: openSidebar ? 'none' : '' }}
                onClick={() => setOpenSidebar(!openSidebar)}>
                <svg
                  width="22"
                  height="18"
                  viewBox="0 0 22 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1 1H21M1 9H21M1 17H21"
                    stroke="#ffffff"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
