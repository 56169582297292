import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AdminInput from '../common/AdminInput';
import Button from '../common/Button';
import InputSelect from '../common/InputSelect';
import CheckBoxInput from '../common/checkBox';
import { getTranslation } from '../../utils/translation';

const SignupForm = ({
  teamType = 'team',
  formik,
  regions = [],
  establishments = [],
  academies = [],
  schools = [],
  languageOption,
  from = 'iframe',
  registrationForm = [],
  submitButtonType = 'primary'
}) => {
  const { t, i18n } = useTranslation();

  const [showOtherSchoolField, setShowOtherSchoolField] = useState(false);

  const fieldEnabled = (fieldName) => {
    return registrationForm.find((field) => field.id === fieldName)?.enabled;
  };

  return (
    <form onSubmit={formik.handleSubmit} className="signupform w-full mx-auto">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 px-1 sm:px-3">
        {/* Organization Type */}
        {fieldEnabled('type') && (
          <InputSelect
            className="!mb-1 border-2 bg-transparent border-[#191919] w-full"
            label={getTranslation(t, 'signup.fields.type')}
            placeholder={getTranslation(t, 'signup.placeholder.type')}
            name="type"
            value={formik.values.type}
            options={establishments.map((establishment) => ({
              value: establishment._id,
              label: establishment['name_' + i18n.language]
            }))}
            menuPlacement={'top'}
            touched={formik.touched.type}
            error={formik.errors.type}
            onChange={(value) => formik.setFieldValue('type', value)}
          />
        )}
        {/* Organization name */}
        {fieldEnabled('name') && (
          <AdminInput
            label={getTranslation(t, 'signup.fields.name')}
            placeholder={getTranslation(t, 'signup.placeholder.name')}
            className="my-1 signupinput bg-transparent border-2 border-[#191919] rounded-[45px] w-full"
            name="name"
            defaultMargin={'mb-4'}
            formik={formik}
          />
        )}

        {/* Org Address */}
        {fieldEnabled('address') && (
          <AdminInput
            label={getTranslation(t, 'signup.fields.address')}
            placeholder={getTranslation(t, 'signup.placeholder.address')}
            className="my-1 signupinput bg-transparent border-2 border-[#191919] rounded-[45px] w-full"
            name="address"
            defaultMargin={'mb-4'}
            formik={formik}
          />
        )}
        {/* Org city */}
        {fieldEnabled('city') && (
          <AdminInput
            label={getTranslation(t, 'signup.fields.city')}
            placeholder={getTranslation(t, 'signup.placeholder.city')}
            className="my-1 signupinput bg-transparent border-2 border-[#191919] rounded-[45px] w-full"
            name="city"
            defaultMargin={'mb-4'}
            formik={formik}
          />
        )}

        {/* Organization academy */}
        {fieldEnabled('academy') && (
          <InputSelect
            className="!mb-1 signupinput bg-transparent border-2 border-[#191919] rounded-[45px] w-full"
            label={getTranslation(t, 'signup.fields.academy')}
            placeholder={getTranslation(t, 'signup.placeholder.academy')}
            name="academy"
            value={formik.values.academy}
            options={academies.map((academy) => ({
              value: academy._id,
              label: academy['name_' + i18n.language]
            }))}
            menuPlacement={'top'}
            touched={formik.touched.academy}
            error={formik.errors.academy}
            onChange={(value) => formik.setFieldValue('academy', value)}
          />
        )}
        {/* Organization postal code */}
        {fieldEnabled('postalCode') && (
          <AdminInput
            label={getTranslation(t, 'signup.fields.postalCode')}
            placeholder={getTranslation(t, 'signup.placeholder.postalCode')}
            className="my-1 signupinput bg-transparent border-2 border-[#191919] rounded-[45px] w-full"
            name="postalCode"
            defaultMargin={'mb-4'}
            formik={formik}
          />
        )}

        {/* First Name */}
        {fieldEnabled('firstName') && (
          <AdminInput
            label={getTranslation(t, 'signup.fields.firstName')}
            placeholder={getTranslation(t, 'signup.placeholder.firstName')}
            className="my-1 signupinput bg-transparent border-2 border-[#191919] rounded-[45px] w-full"
            name="firstName"
            defaultMargin={'mb-4'}
            formik={formik}
          />
        )}
        {/* Last Name */}
        {fieldEnabled('lastName') && (
          <AdminInput
            label={getTranslation(t, 'signup.fields.lastName')}
            placeholder={getTranslation(t, 'signup.placeholder.lastName')}
            className="my-1 signupinput bg-transparent border-2 border-[#191919] rounded-[45px] w-full"
            name="lastName"
            defaultMargin={'mb-4'}
            formik={formik}
          />
        )}

        {/* School Organization */}
        {fieldEnabled('schoolOrganization') && teamType === 'school' && (
          <AdminInput
            label={getTranslation(t, `signup.fields.school.${from}`)}
            placeholder={getTranslation(t, 'signup.placeholder.school')}
            className="my-1 signupinput bg-transparent border-2 border-[#191919] rounded-[45px] w-full"
            name="schoolOrganization"
            defaultMargin={'mb-4'}
            formik={formik}
          />
        )}
        {fieldEnabled('schoolOrganization') && teamType === 'team' && (
          <div>
            <InputSelect
              className="!mb-1 signupinput bg-transparent border-2 border-[#191919] rounded-[45px] w-full"
              label={getTranslation(t, `signup.fields.school.${from}`)}
              placeholder={getTranslation(t, 'signup.placeholder.school')}
              name="schoolOrganization"
              value={formik.values.schoolOrganization}
              options={schools.map((school) => ({
                value: school._id,
                label: school?.name
              }))}
              menuPlacement={'top'}
              touched={formik.touched.schoolOrganization}
              error={formik.errors.schoolOrganization}
              onChange={(value) => {
                const schoolOrgValue = schools.find((school) => school._id === value);
                formik.setFieldValue('schoolOrganization', schoolOrgValue);
                setShowOtherSchoolField(value === 'other');
              }}
            />

            {showOtherSchoolField && (
              <AdminInput
                // label={getTranslation(t, 'signup.fields.schoolOrganizationOther')}
                placeholder={getTranslation(t, 'signup.placeholder.schoolOrganizationOther')}
                className="my-1 signupinput bg-transparent border-b-2 border-[#191919] rounded-[45px] w-full"
                name="schoolOrganizationOther"
                defaultMargin={'mb-4'}
                formik={formik}
                focus={false}
              />
            )}
          </div>
        )}
        {/* Function / task in your school / organization */}
        {fieldEnabled('functionTask') && (
          <AdminInput
            label={getTranslation(t, 'signup.fields.function')}
            placeholder={getTranslation(t, 'signup.placeholder.function')}
            className="my-1 signupinput bg-transparent border-2 border-[#191919] rounded-[45px] w-full"
            name="jsonFunction"
            defaultMargin={'mb-4'}
            formik={formik}
          />
        )}

        {/* Email */}
        {fieldEnabled('email') && (
          <AdminInput
            label={getTranslation(t, 'signup.fields.email')}
            placeholder={getTranslation(t, 'signup.placeholder.email')}
            className="my-1 signupinput bg-transparent border-2 border-[#191919] rounded-[45px] w-full"
            name="email"
            defaultMargin={'mb-4'}
            formik={formik}
          />
        )}
        {/* Phone */}
        {fieldEnabled('phone') && (
          <AdminInput
            label={getTranslation(t, 'signup.fields.phone')}
            placeholder={getTranslation(t, 'signup.placeholder.phone')}
            className="my-1 signupinput bg-transparent border-2 border-[#191919] rounded-[45px] w-full"
            name="phone"
            type="tel"
            defaultMargin={'mb-4'}
            formik={formik}
          />
        )}

        {/* Canton */}
        {fieldEnabled('canton') && (
          <InputSelect
            className="!mb-1 signupinput bg-transparent border-2 border-[#191919] rounded-[45px] w-full"
            label={getTranslation(t, 'signup.fields.canton')}
            placeholder={getTranslation(t, 'signup.placeholder.canton')}
            name="canton"
            value={formik.values.canton}
            options={regions.map((region) => ({
              value: region._id,
              label: region['name_' + i18n.language]
            }))}
            menuPlacement={'top'}
            touched={formik.touched.canton}
            error={formik.errors.canton}
            onChange={(value) => formik.setFieldValue('canton', value)}
          />
        )}
        {/* language */}
        {fieldEnabled('language') && (
          <InputSelect
            className="!mb-1 signupinput bg-transparent border-2 border-[#191919] rounded-[45px] w-full"
            label={getTranslation(t, 'signup.fields.language')}
            placeholder={getTranslation(t, 'signup.placeholder.language')}
            name="language"
            value={formik.values.language}
            options={languageOption}
            menuPlacement={'top'}
            onChange={(value) => formik.setFieldValue('language', value)}
            touched={formik.touched.language}
            error={formik.errors.language}
          />
        )}

        {/* isTeacher */}
        {fieldEnabled('isTeacher') && (
          <CheckBoxInput
            label={getTranslation(t, 'signup.fields.isTeacher')}
            className="my-1 flex w-full"
            type="checkbox"
            name="isTeacher"
            value={formik.values.isTeacher}
            touched={formik.touched.isTeacher}
            onChange={formik.handleChange}
            fieldValue={formik.values.isTeacher}
            onBlur={formik.handleBlur}
            checked={formik.values.isTeacher}
            error={formik.errors.isTeacher}
          />
        )}

        {/* wantAccess */}
        {fieldEnabled('wantAccess') && (
          <CheckBoxInput
            label={getTranslation(t, 'signup.fields.wantAccess')}
            className="my-1 flex w-full"
            type="checkbox"
            name="wantAccess"
            fieldValue={formik.values.wantAccess}
            value={formik.values.wantAccess}
            touched={formik.touched.wantAccess}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            checked={formik.values.wantAccess}
            error={formik.errors.wantAccess}
          />
        )}
      </div>

      <div className="text-center mt-6 mb-2 flex justify-center mx-auto">
        <Button
          type="submit"
          buttonType={submitButtonType}
          loading={formik.isSubmitting}
          loadingColor={'#fff'}
          // disabled={formik.isSubmitting || Object.keys(formik.errors)?.length > 0}
          data-testid="submit-button">
          {getTranslation(t, 'signup.button')}
        </Button>
      </div>
    </form>
  );
};
export default SignupForm;
