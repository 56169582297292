import React, { useState, useEffect } from 'react';
import LanguageSelectDropdown from '../../common/languageSelectDropdown';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import clsx from 'clsx';

import { ModalCloseIcon } from '../../icons';

import { languageOption } from '../../../utils/data';
import { fetchCorrectTranslation } from '../../../utils/translation';

export default function Menu({ menuData, showMenu, setShowMenu, colorScheme = 'wsf' }) {
  const { i18n } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState({
    label: 'DE',
    value: 'de'
  });

  const colors = {
    container: colorScheme === 'swiss' ? 'bg-swiss-100' : 'bg-cblue-500',
    backButton: colorScheme === 'swiss' ? 'bg-swiss-200' : 'bg-cblue-300'
  };

  const hideMenu = () => setShowMenu(false);

  const handleChange = (e) => {
    setSelectedLanguage(e);
    i18n.changeLanguage(e.value);
  };

  useEffect(() => {
    const currentLang = i18n.language;
    const changedLang = languageOption.filter((item) => {
      return item.value === currentLang;
    });
    setSelectedLanguage(changedLang);
  }, [i18n.language]);

  useEffect(() => {
    if (!document.getElementById('root').classList) return;
    if (showMenu) {
      document.getElementById('root').classList.add('overflow-y-hidden', 'h-screen');
    } else {
      document.getElementById('root').classList.remove('overflow-y-hidden', 'h-screen');
    }
  }, [showMenu]);

  return (
    <nav
      className={clsx(
        'slide-menu-container  w-full h-screen left-[-100%] top-0 fixed z-50 overflow-y-auto  ',
        'md:w-12/12',
        `${showMenu ? 'open' : 'close'}`,
        colors.container
      )}>
      <div className="hidden md:flex justify-between ">
        <div className="grow w-2/6 h-screen relative  border-red-600">
          <div className="side-logo-dk-1 bg-swiss-150 absolute left-0 top-0  h-full w-full  "></div>
          <div className="absolute left-0 top-0 side-logo-dk-2 bg-swiss-300 h-2/6 w-full"></div>
          <div className="absolute left-0 bottom-0 side-logo-dk-3 bg-swiss-100 h-1/6 w-full"></div>
        </div>
        <div className="grow">
          <div className="flex-col">
            <div className="flex flex-row justify-end items-center">
              <div className="max-w-28 mt-4 ml-4">
                <LanguageSelectDropdown
                  backgroundColor="white"
                  value={selectedLanguage}
                  options={languageOption}
                  onChange={handleChange}
                  themeId="ch"
                />
              </div>
              <div className="text-left px-8 ">
                <button
                  className={clsx('text-black text-3xl rounded-full h-8 w-8 text-center')}
                  onClick={hideMenu}>
                  x
                </button>
              </div>
            </div>
            <div>
              <div className="px-8 mt-20">
                <ul className="!list-none">
                  {menuData?.constructor === Array &&
                    menuData.map((item, index) => {
                      return (
                        <li key={index} className="mb-6 text-center md:text-left">
                          <HashLink
                            scroll={(el) =>
                              el.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center'
                              })
                            }
                            to={fetchCorrectTranslation(item.link)}
                            onClick={hideMenu}
                            className={item?.className}>
                            {item.type?.default !== 'button' ? (
                              <span className="text-black  text-base md:text-xl">
                                {fetchCorrectTranslation(item.title)}{' '}
                                {item.subtitle && (
                                  <span
                                    className={`text-black block md:inline-block ${fetchCorrectTranslation(
                                      item?.subtitleClass
                                    )}`}>
                                    {fetchCorrectTranslation(item.subtitle)}
                                  </span>
                                )}
                              </span>
                            ) : (
                              <span
                                className={`block w-fit mx-auto md:ml-0 font-bold text-lg px-8 py-1 rounded-full mt-2 md:mt-6 ${
                                  item.className !== undefined
                                    ? item.className
                                    : ' bg-swiss-300 px-8 py-4 text-white hover:opacity-80 outline-none hover:bg-swiss-button-600 border-transparent hover:border-swiss-300 border-2'
                                }`}>
                                {console.log('item.className', item.className)}
                                {fetchCorrectTranslation(item.title)}
                              </span>
                            )}
                          </HashLink>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative h-screen md:hidden">
        <div className="flex justify-between bg-swiss-300">
          <div className="max-w-28 mt-4 ml-4">
            <LanguageSelectDropdown
              backgroundColor="white"
              value={selectedLanguage}
              options={languageOption}
              onChange={handleChange}
              themeId="ch"
            />
          </div>
          <div className="text-left px-8 flex items-center">
            <button
              className={clsx('text-white text-xl rounded-full h-8 w-8 text-center my-6')}
              onClick={hideMenu}>
              <ModalCloseIcon color="#FFFFFF" />
            </button>
          </div>
        </div>
        <div className="px-8 mt-20 relative h-screen">
          <div className="absolute left-0 bottom-0 h-1/6 w-5/6 bg-swiss-250 side-nav-logo -z-10"></div>
          <ul className="!list-none">
            {menuData &&
              menuData.map((item, index) => {
                const itemType = fetchCorrectTranslation(item.type);
                return (
                  <li
                    key={index}
                    className="mb-6 text-left md:text-left mt-[20px] md:mt-[50px] px-10 font-bold">
                    <HashLink
                      scroll={(el) =>
                        el.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                        })
                      }
                      to={fetchCorrectTranslation(item.link)}
                      onClick={hideMenu}
                      className={item?.className}>
                      {itemType !== 'button' ? (
                        <span className="text-black font-bold text-2xl">
                          {fetchCorrectTranslation(item.title)}{' '}
                          {item.subtitle && (
                            <span
                              className={`text-black block md:inline-block ${item?.subtitleClass}`}>
                              {fetchCorrectTranslation(item.subtitle)}
                            </span>
                          )}
                        </span>
                      ) : (
                        <span
                          className={`block w-fit mx-auto md:ml-0 font-bold text-lg px-8 py-1 rounded-full mt-2 md:mt-6 ${
                            item.className !== undefined
                              ? item.className
                              : 'bg-swiss-300 px-8 py-4 text-white hover:opacity-80 outline-none hover:bg-swiss-button-600 border-transparent hover:border-swiss-300 border-2'
                          }`}>
                          {fetchCorrectTranslation(item.title)}
                        </span>
                      )}
                    </HashLink>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </nav>
  );
}
