import React from 'react';

const ResponsiveVimeoVideo = ({ url }) => {
  return (
    <>
      <div
        style={{
          padding: '56.25% 0 0 0',
          position: 'relative',
          borderRadius: '5px',
          overflow: 'hidden'
        }}>
        <iframe
          src={url}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100% '
          }}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </>
  );
};

export default ResponsiveVimeoVideo;
